import React from 'react'; 
import bun from "./bun.gif";
export function Bunny() {
    return (
        <div> 
        <div style={{display: "flex", justifyContent: "center"}}>
          <img width={330} src={bun} alt='bunni' />
        </div> 
        <div style={{display: "flex", justifyContent: "center", textAlign:"center"}}>
        <h3 style={{color: "#f3b3cc"}}>

happy anniversary beesbees!⋆𐙚₊˚⊹♡ 
<br></br>
i coded all this myself! (with the help of pookie of course). i thought it would be cuter and more sentimental than a physical love letter.
<br></br>
i love you so much, i hope you like everything. i know it doesn’t seem much but we have an entire lifetime to exchange cute stuff together. 
<br></br>
i hope i can spend a long time with you. you've been around me almost my entire life anyways :p. you’re the cutest tiniest smallest stupidest hairiest stinkiest smelliest gorjus and most ethical beesbees ever. you’re the bestest boy ever and you deserve the world and more. you’re the person i want to come home to in the future and the person i want to share both the good and bad things in life. no matter what we go through i love you more than any stupid thing that happens. i want to be the best for you so you dont groan and sigh when someone asks if you’re married in the future (and for you to be happy of course). 
<br></br>
you’ll always be the person i have the most cherished memories with. everytime i look at you i feel my heart warm up in ways i never thought its like a lobotomized shocktherapy. everything about you is so sweet and endearing. i hope you can comfortably be yourself around me forever. i dont tell you this enough but you mean everything to me and more. its crazy to me how you're still here to be honest.
<br></br>
you're my best friend and i support you with my whole heart. i care for you deeply and im grateful you're here and alive and breathing. thank you for being here for me, for loving me, for listening to me, and for being patient with me. you're the best °ᡣ𐭩 . ° .
<br></br>
aa oov oo oo mpwa :* </h3>
        </div>
        </div>
    );

}