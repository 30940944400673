import React from 'react';
import {useHistory} from "react-router-dom";
import pink from "./pink.JPG"
export function Letter() {
    let history = useHistory();
    function handleClick() {
        history.push("/Bunny");
    }
  return (
    <div>
    <div style={{marginLeft: 350, marginTop: 130}}>
    <h2 style={{color: "#f3b3cc"}}>for beesa,</h2>
    </div>
    <div style={{marginLeft: 400}}>
      <h2 style={{color: "#f3b3cc"}}>happy one year &lt;3</h2>
    </div>
    <div style={{justifyContent: "center", display: "flex"}}>
    <img width={330} src={pink} alt="altie" onClick={() => handleClick()}/>
    </div>
    <div style={{direction: "rtl", marginRight: 330}}>
      <h2 style={{color: "#f3b3cc"}}>with love, bubu</h2>
    </div>
  </div>
  );
}